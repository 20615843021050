<template>
    <div class="offer-mobile active">
        <a target="_blank"
           :href="'/Poskytovatel/' + item.BankCode"
           @click="gtmTrackClickUI('Detail partnera', {partner: item.BankCode});">
            <img :src="'/ImageProxy.aspx?f=' + item.BankCode + '-big.png'" class="small-img-table-mobile" />
        </a>

        <template v-if="item.CalcState === 'CompletedWithData'">
            <div class="offer-mobile-parameters">
                <template>
                    <div>
                        Max. výše úvěru
                        <vue-numeric v-if="this.item.MaxAmount > 0"
                                     :currency="$t('global.currencySymbol')"
                                     currency-symbol-position="suffix"
                                     separator="space"
                                     read-only="true"
                                     v-model="itemMaxAmount"
                                     class="bold"></vue-numeric>
                        <span v-else>{{ $t('credit.resultTableItem.loanCant') }}</span>
                    </div>
                    <div>
                        {{ $t('credit.resultTable.maturity') }}
                        <vue-numeric :currency="$t('global.years')"
                                     v-if="item.Maturity > 0 && this.item.MaxAmount > 0 && itemMaturityMonths == 0"
                                     currency-symbol-position="suffix"
                                     separator="space"
                                     read-only="true"
                                     v-model="itemMaturityYears"
                                     class="bold"></vue-numeric><br />
                        <vue-numeric :currency="$t('global.month')"
                                     v-if="item.Maturity > 0 && this.item.MaxAmount > 0 && itemMaturityMonths == 1"
                                     currency-symbol-position="suffix"
                                     separator="space"
                                     read-only="true"
                                     v-model="itemMaturityMonths"
                                     class="bold"></vue-numeric>
                        <vue-numeric :currency="$t('global.months2')"
                                     v-if="item.Maturity > 0 && this.item.MaxAmount > 0 && itemMaturityMonths >= 2 && itemMaturityMonths < 5"
                                     currency-symbol-position="suffix"
                                     separator="space"
                                     read-only="true"
                                     v-model="itemMaturityMonths"
                                     class="bold"></vue-numeric>
                        <vue-numeric :currency="$t('global.months')"
                                     v-if="item.Maturity > 0 && this.item.MaxAmount > 0 && itemMaturityMonths >= 5"
                                     currency-symbol-position="suffix"
                                     separator="space"
                                     read-only="true"
                                     v-model="itemMaturityMonths"
                                     class="bold"></vue-numeric>
                    </div>
                    <div>
                        {{ $t('credit.resultTable.payment') }}
                        <vue-numeric :currency="$t('global.currencySymbol')"
                                     v-if="item.MaxAmount > 0"
                                     currency-symbol-position="suffix"
                                     separator="space"
                                     read-only="true"
                                     v-model="itemInstallment"
                                     class="bold"></vue-numeric>
                    </div>
                    <template v-if="!isMoneta">
                        <div>
                            {{ $t('credit.resultTable.totalIncome') }}
                            <vue-numeric v-if="item.MaxAmount > 0"
                                         :currency="$t('global.currencySymbol')"
                                         currency-symbol-position="suffix"
                                         separator="space"
                                         read-only="true"
                                         v-model="item.AppliedIncomeTotal"
                                         class="bold"></vue-numeric>
                        </div>
                        <div>
                            {{ $t('credit.resultTable.dti') }}
                            <vue-numeric v-if="item.MaxAmount > 0"
                                         decimal-separator=","
                                         precision="2"
                                         read-only="true"
                                         v-model="itemDti"
                                         class="bold"></vue-numeric>
                        </div>
                        <div>
                            {{ $t('credit.resultTable.dsti') }}
                            <vue-numeric v-if="item.MaxAmount > 0"
                                         decimal-separator=","
                                         precision="2"
                                         read-only="true"
                                         v-model="itemDsti"
                                         currency="%"
                                         currency-symbol-position="suffix"
                                         class="bold"></vue-numeric>
                        </div>
                    </template>
                </template>

            </div>
            <div v-if="isMoneta" class="text-center">
                <span class="badgeOverenoOnline badge">{{ $t('credit.resultTableItem.onlineApproved') }}</span>
            </div>
            <div v-else class="text-center">
                <span class="income-icons" style="font-size: 17px;">
                    <img :class="{'gray': item.AppliedEmployeeIncome === false}" src="@/assets/icons/prijmy_zavisla_cinnost.svg" v-bTooltip="$t('credit.resultTableItem.AppliedEmployeeIncome')" />
                    <img :class="{'gray': item.AppliedBusinessIncome === false}" src="@/assets/icons/prijmy_podnikani.svg" v-bTooltip="$t('credit.resultTableItem.AppliedBusinessIncome')" />
                    <img :class="{'gray': item.AppliedBusinessIncomeDph === false}" src="@/assets/icons/prijmy_obrat.svg" v-bTooltip="$t('credit.resultTableItem.AppliedBusinessIncomeDph')" />
                    <img :class="{'gray': item.AppliedLeaseIncome === false}" src="@/assets/icons/prijmy_pronajem.svg" v-bTooltip="$t('credit.resultTableItem.AppliedLeaseIncome')" />
                    <img :class="{'gray': item.AppliedOtherIncome === false}" src="@/assets/icons/prijmy_ostatni.svg" v-bTooltip="$t('credit.resultTableItem.AppliedOtherIncome')" />
                    <img v-if="!isSk" :class="{'gray': item.AppliedFlatRateIncome === false}" src="@/assets/icons/prijmy_pausal.svg" v-bTooltip="$t('credit.resultTableItem.AppliedFlatRateIncome')" />
                </span>
            </div>


            <div class="offer-mobile-buttons dropdown">
                <button type="button"
                        class="btn btn-default-gray"
                        @click="linkToChart"
                        v-bTooltip="$t('credit.resultTableItem.gotoPaymentChart')">
                    <img class="chart left" src="@/assets/icons/splatky-graf.svg" /> {{ $t('credit.resultTable.buttons.chart') }}
                </button>
                <a href="item.BankOnlineContractLink"
                   @click="gtmTrackClickUI('Sjednání', {partner: item.BankCode});"
                   v-if="showOnlineContractLink"
                   class="btn btn-primary btn-arrange"
                   target="_blank"
                   v-bTooltip="$t('credit.resultTableItem.gotoBankOnlineContractSite') +' ' + item.BankCode">{{ $t('credit.resultTable.buttons.arrange') }}</a>

                <button type="button"
                        v-if="dorisMortgagesAllowed && item.BankCode == 'HB'"
                        class="btn btn-primary btn-arrange dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        v-bTooltip="$t('credit.resultTableItem.gotoBankOnlineContractSite') +' ' + item.BankCode">
                    {{ $t('credit.resultTable.buttons.arrange') }} <img class="mr-6" src="@/assets/icons/downFill.svg" />
                </button>
                <ul class="dropdown-menu dropdown-menu-right"
                    v-if="dorisMortgagesAllowed && item.BankCode == 'HB'">
                    <li>
                        <a :href="pricingOfferLink" :target="dorisLinkTarget"
                           @click="gtmTrackClickUI('Sjednání cenové nabídky', {partner: item.BankCode});">Garance úrokové sazby</a>
                    </li>
                    <li>
                        <a href="/Doris?login=true&redirectTo=/Mortgages/cz/Application/CreateNewApplication/HB" :target="dorisLinkTarget"
                           @click="gtmTrackClickUI('Sjednání online žádost', {partner: item.BankCode});">Online žádost</a>
                    </li>
                </ul>
            </div>

        </template>

        <template v-if="item.CalcState === 'Loading' || item.CalcState === 'Placeholder'">
            <div class="text-center">
                <template v-if="calcInProgress">
                    <i class="fas fa-spinner fa-spin"></i> {{ $t('credit.resultTableItemLoading.counting') }}
                </template>
                <template v-else>
                    <i class="fas fa-times"></i> {{ $t('credit.resultTableItemLoading.canceled') }}
                </template>
            </div>
        </template>

        <template v-if="item.CalcState === 'CompletedWithoutData'">
            <div class="text-center">
                {{ $t('credit.resultTableItemError.creditCantBeCounted') }}
            </div>
        </template>

        <div v-for="msg in messages" :key="msg.Message">
            <div :class="{'alert-danger' : msg.Type == 'Fatal', 'alert-info' : msg.Type == 'Info', 'alert-warning' : msg.Type == 'Warning'}" class="alert">
                <p v-html="msg.PublicMessage" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'v-result-table-item-mobile',
        props: {
            item: Object,
            calcInProgress: Boolean,
            loanParams: Object,
            showLoanScheduleLink: Boolean,
            limitlessResult: Boolean,
            dorisMortgagesAllowed: Boolean,
            dorisLinkTarget: String
        },
        computed: {
            calendarHrefLink: function () {
                return '/GuidRedirect.aspx?module=payments&params=' + encodeURIComponent(this.calendarParams);
            },
            refinanceHrefLink: function () {
                return '/GuidRedirect.aspx?module=refinance&params=' + encodeURIComponent(this.calendarParams);
            },
            calendarParams: function () {
                return '?product=' + this.item.PartnerCode
                    + '&amount=' + this.loanParams.loanAmount
                    + '&rate=' + this.item.Rate / 100
                    + '&maturity=' + this.item.Maturity
                    + '&fixation=' + this.item.Fixation
                    ;
            },
            pricingOfferLink() {
                return '/Doris.aspx?action=newPricingOffer&code=HB&rate=' + this.loanParams.rate.toString().replace('.', ',')
                    + '&fixation=' + this.loanParams.fixation
                    + '&maturity=' + this.loanParams.maturity;
            },
            isMoneta: function () {
                return this.item.BankCode === 'MMB';
            },
            showOnlineContractLink: function () {
                return this.item.BankOnlineContractLink != null && this.item.BankOnlineContractLink != "" && this.item.ShowOnlineContractLinkInCreditWorthiness;
            },
            itemDsti: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.Dsti * 100 : this.item.Dsti * 100;
            },
            itemDti: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.Dti : this.item.Dti;
            },
            itemMaxAmount: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.MaxAmount : this.item.MaxAmount;
            },
            itemInstallment: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.Installment : this.item.Installment;
            },
            itemMaturityYears() {
                return Math.floor(parseInt(this.item.Maturity / 12));
            },
            itemMaturityMonths() {
                return parseInt(this.item.Maturity) % 12;
            },
            isSk: function () {
                return this.item.BankCode.substr(0, 3) == 'SK_';
            },
            messages() {
                function UniquePublicMessage(value, index, self) {
                    return self.find(d => d.PublicMessage === value.PublicMessage) === value;
                }
                const uniqueMessages = this.item.Messages.filter(UniquePublicMessage);
                return uniqueMessages.filter(d => !(this.limitlessResult && d.Code === 'StressTest'));
            }
        },
        methods: {
            linkToChart() {
                window.open(this.calendarHrefLink, '_blank');
            }
        }
    }
</script>

<style scoped>
    .income-icons > img {
        width: 20px;
        margin: 2px;
        filter: invert(37%) sepia(29%) saturate(2435%) hue-rotate(185deg) brightness(99%) contrast(88%);
    }

        .income-icons > img.gray {
            filter: invert(92%) sepia(0%) saturate(18%) hue-rotate(243deg) brightness(84%) contrast(91%) !important;
        }

    span.income-icons > i {
        padding-right: 5px;
    }

    span.badgeOverenoOnline {
        text-transform: uppercase;
        background-color: rgba(232, 242, 255, 1);
        color: #000;
        padding: 5px 10px;
        font-weight: 600;
    }

    tr.textGreenColor {
        color: LimeGreen !important;
        font-weight: bold;
    }

    span#linkToBankOnlinePortal {
        padding-right: 10px;
    }

    .fa-signature {
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
        padding: 3px;
        border-radius: 3px;
    }

    .bonitaMaturityColumn {
        padding: 1px 8px;
    }

    tr {
        white-space: nowrap;
    }

    .alert {
        margin: 0;
        padding: 2px;
    }
</style>

<style>
    .lh18 {
        line-height: 1.8 !important;
    }
</style>